import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/en.json";
import sv from "./locales/sv/sv.json";

const resources = {
  en: en,
  sv: { translation: sv },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // default language
  fallbackLng: "en", // fallback language
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  supportedLngs: ['en', 'es', 'sv']
});

export default i18n;
