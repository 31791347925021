import React from "react";

import step1 from "../../images/step1.jpg";
import step2 from "../../images/step2.jpg";
import step3 from "../../images/step3.jpg";

function AppDeleteInstructionsSv() {
  return (
    <div className="container">
      <article className="single-page">
        <div className="row">
          <div className="single-page-content col-md-8">
            <div className="single-page-text">
              <h1>Instruktioner för borttagning av data</h1>
              <p>Supercoach lagrar inte dina personuppgifter, de används endast för inloggning.</p>
              <p>Enligt Google Play-reglerna måste vi tillhandahålla instruktioner för borttagning av användardata. Om du vill radera all din data för Supercoach, följ dessa instruktioner: </p>
              <h2>Alternativ 1</h2>
              <p>Öppna Supercoach-appen. Gå sedan till fliken "Inställningar". Klicka på "Profilinställningar", klicka på Ta bort konto och bekräfta sedan borttagningen av kontot genom att klicka på knappen "Ta bort konto".</p>
              <div className="delete-images">
                <img src={step1} alt="settings tab"/>
                <img src={step2} alt="profile screen"/>
                <img src={step3} alt="Delete account"/>
              </div>
              <h2>Alternativ 2</h2>
              Gå till <a href="https://api.supercoachapp.com/deletion">RADERA ANVÄNDARKONTOSIDA</a> och ange inloggningsuppgifter för att bekräfta borttagningen av kontot.
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}

export default AppDeleteInstructionsSv;
