import React from "react";
import { Link } from "react-router-dom";

function TermsOfService() {
  return (
    <div className="container">
      <article className="single-page">
        <div className="row">
          <div className="single-page-content col-md-8">
            <div className="single-page-text">
              <h1>Terms of Service</h1>
              <p>These terms and conditions outline the rules and regulations for the use of Supercoach
                Soccer.</p>
              <p>By accessing this app we assume you accept these terms and conditions in full. Do not continue to
                use Supercoach Soccer if you do not accept all of the terms and conditions stated on this page.&nbsp;We
                reserve the right to update these terms at any time, without previous notice.</p>
              <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer
                Notice and any or all Agreements: ”Client”, “You” and “Your” refers to you, the person accessing
                this website and accepting the Company’s terms and conditions. ”The Company”, “Ourselves”, “We”,
                and “Our”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and
                ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance and
                consideration of payment necessary to undertake the process of our assistance to the Client in
                the most appropriate manner, whether by formal meetings of a fixed duration, or any other means,
                for the express purpose of meeting the Client’s needs in respect of provision of the Company’s
                stated services/products, in accordance with and subject to, prevailing law of Sweden. Any use
                of the above terminology or other words in the singular, plural, capitalisation and/or he/she or
                they, are taken as interchangeable and therefore as referring to same.</p>
              <p><strong>Cookies and Privacy</strong></p>
              <p>We employ the use of cookies. By using our&nbsp;website or app you consent to the use of cookies
                in accordance with Supercoachs <Link to="/privacy-policy">privacy policy</Link>.</p>
              <p>Most of the modern day interactive web sites use cookies to enable us to retrieve user details
                for each visit. Cookies are used in some areas of our site to enable the functionality of this
                area and ease of use for those people visiting. Some of our affiliate / advertising partners may
                also use cookies.</p>
              <p><strong>License</strong></p>
              <p>Unless otherwise stated, Digitala Fotbollsakademin AB and/or it’s licensors own the intellectual
                property rights for all material on Digitala Fotbollsakademin AB All intellectual property
                rights are reserved. You may view and/or print pages from http://www.supercoachapp.com or our
                app for your own personal use subject to restrictions set in these terms and conditions.</p>
              <p>You must not:</p>
              <ul>
                <li>Republish material from http://www.supercoachapp.com or our apps</li>
                <li>Sell, rent or sub-license material from http://www.supercoachapp.com or our apps</li>
                <li>Reproduce, duplicate or copy material from http://www.supercoachapp.com or our apps</li>
                <li>Redistribute content from Digitala Fotbollsakademin AB (unless content is specifically made
                  for redistribution).
                </li>
              </ul>
              <p><strong>Iframes</strong></p>
              <p>Without prior approval and express written permission, you may not create frames around our Web
                pages or use other techniques that alter in any way the visual presentation or appearance of our
                Web site.</p>
              <p><strong>Content Liability</strong></p>
              <p>We shall have no responsibility or liability for any content appearing on your Web site. You
                agree to indemnify and defend us against all claims arising out of or based upon your Website.
                No link(s) may appear on any page on your Web site or within any context containing content or
                materials that may be interpreted as libelous, obscene or criminal, or which infringes,
                otherwise violates, or advocates the infringement or other violation of, any third party
                rights.</p>
              <p><strong>Reservation of Rights</strong></p>
              <p>We reserve the right at any time and in its sole discretion to request that you remove all links
                or any particular link to our Web site. You agree to immediately remove all links to our Web
                site upon such request. We also reserve the right to amend these terms and conditions and its
                linking policy at any time. By continuing to link to our Web site, you agree to be bound to and
                abide by these linking terms and conditions.</p>
              <p><strong>Removal of links from our website</strong></p>
              <p>If you find any link on our Web site or any linked web site objectionable for any reason, you may
                contact us about this. We will consider requests to remove links but will have no obligation to
                do so or to respond directly to you.</p>
              <p>Whilst we endeavour to ensure that the information on this website is correct, we do not warrant
                its completeness or accuracy; nor do we commit to ensuring that the website remains available or
                that the material on the website is kept up to date.</p>
              <p><strong>Disclaimer</strong></p>
              <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and
                conditions relating to our website and the use of this website (including, without limitation,
                any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the
                use of reasonable care and skill). Nothing in this disclaimer will:</p>
              <ul>
                <li>limit or exclude our or your liability for death or personal injury resulting from
                  negligence;
                </li>
                <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                <li>limit any of our or your liabilities in any way that is not permitted under applicable law;
                  or<br/>
                    exclude any of our or your liabilities that may not be excluded under applicable law.
                </li>
              </ul>
              <p>The limitations and exclusions of liability set out in this Section and elsewhere in this
                disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising
                under the disclaimer or in relation to the subject matter of this disclaimer, including
                liabilities arising in contract, in tort (including negligence) and for breach of statutory
                duty.</p>
              <p>To the extent that the website and the information and services on the website are provided free
                of charge, we will not be liable for any loss or damage of any nature.</p>
              <p><strong>Contact Information</strong></p>
              <p>If there are any questions regarding these terms, you may contact us using the information
                below.</p>
              <p>www.supercoachapp.com<br/>
                Svartmangatan 9<br/>
                Stockholm, 111 29<br/>
                Sweden</p>
              <p>hello@supercoachapp.com</p>
              <p>Last Edited on 2018-05-24</p>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}

export default TermsOfService;
