import React from "react";
import { Link } from "react-router-dom";

function TermsOfService() {
  return (
    <div className="container">
    <article className="single-page">
      <div className="row">
        <div className="single-page-content col-md-8">
          <div className="single-page-text">
            <h1>Användarvillkor</h1>
            <p>Dessa villkor beskriver reglerna och bestämmelserna för användningen av Supercoach Soccer.</p>
            <p>Genom att använda den här appen tar vi för givet att du accepterar dessa användarvillkor till fullo. Fortsätt inte att använda Supercoach Soccer om du inte accepterar alla villkor som anges på denna sida.&nbsp;Vi
               förbehåller oss rätten att uppdatera dessa villkor när som helst, utan att det föregås av ett meddelande.</p>
            <p>Följande terminologi gäller för dessa villkor, Integritetsspolicy och Ansvarsfriskrivningsmeddelande och alla avtal: "Kund", "Du" och "Din" hänvisar till dig, den person som har tillgång till
               denna webbplats och accepterar företagets villkor. ”Företaget”, ”Oss själva”, ”Vi”,
               och "Vårt", syftar på vårt företag. "Part", "Parter" eller "Oss", avser både Kunden och
               oss själva, eller antingen kunden eller oss själva. Alla villkor avser erbjudande, accept och
               övervägande av betalning som krävs för att genomföra processen för vår assistans till kunden på
               det lämpligaste sättet, antingen genom formella möten med en bestämd varaktighet eller på något annat sätt,
               i det uttryckliga syftet att tillgodose kundens behov i fråga om tillhandahållande av företagets
               angivna tjänster/produkter, i enlighet med och med förbehåll för gällande lag i Sverige. All användning
               av ovanstående terminologi eller andra ord i singular, plural, versaler och/eller han/hon eller
               de tas som utbytbara och därför som hänvisningar till desamma.</p>
            <p><strong>Cookies och Sekretess</strong></p>
            <p>Vi använder oss av cookies. Genom att använda vår&nbsp;webbplats eller app samtycker du till användningen av cookies
               i enlighet med Supercoachs <Link to="/privacy-policy">integritetspolicy</Link>.</p>
            <p>De flesta av moderna interaktiva webbplatser använder cookies för att man ska kunna hämta användarinformation
               för varje besök. Cookies används i vissa delar av vår webbplats för att möjliggöra denna funktionalitet
               och användarvänlighet för de personer som besöker. Vissa av våra affiliate/annonspartners kan också
               använda cookies.</p>
            <p><strong>Licens</strong></p>
            <p>Om inte annat anges äger Supersports International AB och/eller dess licensgivare den intellektuella
              äganderätten till allt material på Supersports International AB. Alla rättigheter till immateriell egendom förbehålls. Du kan se och/eller skriva ut sidor från http://www.supercoachapp.com eller vår
               app för ditt eget personliga bruk med förbehåll för de begränsningar som anges i dessa villkor.</p>
            <p>Du får inte:</p>
            <ul>
              <li>Återpublicera material från http://www.supercoachapp.com eller någon av våra appar</li>
              <li>Sälja, hyra ut eller underlicensiera material från http://www.supercoachapp.com eller våra appar</li>
              <li>Reproducera, duplicera eller kopiera material från http://www.supercoachapp.com eller våra appar</li>
              <li>Vidaredistribuera innehåll från Supersports International AB (såvida inte innehållet är specifikt gjort för vidaredistribution).
              </li>
            </ul>
            <p><strong>Iframes</strong></p>
            <p>Utan föregående godkännande och uttryckligt skriftligt tillstånd får du inte skapa ramar runt vår webbsida
               eller använda andra tekniker som på något sätt förändrar vår visuella presentation eller utseende
               på vår hemsida.</p>
            <p><strong>Innehållsansvar</strong></p>
            <p>Vi har inget ansvar för något innehåll som visas på din webbplats. Du
               samtycker till att gottgöra och försvara oss mot alla anspråk som härrör från eller baserat på din webbplats.
               Inga länkar får visas på någon sida på din webbplats eller i något sammanhang som innehåller innehåll eller
               material som kan tolkas som ärekränkande, obscent eller brottsligt, eller som gör intrång,
               på annat sätt bryter mot, eller förespråkar intrång eller annan kränkning av, någon tredje parts
               rättigheter.</p>
            <p><strong>Förbehåll av rättigheter</strong></p>
            <p>Vi förbehåller oss rätten att när som helst och efter eget gottfinnande begära att du tar bort alla länkar
               eller någon speciell länk till vår webbplats. Du samtycker till att omedelbart ta bort alla länkar till vår webb
               webbplats på sådan begäran. Vi förbehåller oss också rätten att ändra dessa villkor och dess
               länkpolicy när som helst. Genom att fortsätta att länka till vår webbplats samtycker du till att vara bunden till och
               följa dessa länkningsvillkor.</p>
            <p><strong>Borttagning av länkar från vår webbplats</strong></p>
            <p>Om du finner någon länk på vår webbplats eller någon länkad webbplats stötande av någon anledning, kan du
               kontakta oss om detta. Vi kommer att överväga förfrågningar om att ta bort länkar men har ingen skyldighet att
               göra det eller svara direkt till dig.</p>
            <p>Även om vi strävar efter att säkerställa att informationen på denna webbplats är korrekt, garanterar vi inte
               dess fullständighet eller noggrannhet; inte heller förbinder vi oss att se till att webbplatsen förblir tillgänglig eller
               att materialet på hemsidan hålls uppdaterat.</p>
            <p><strong>Ansvarsfriskrivning</strong></p>
            <p>I den maximala utsträckning som tillåts enligt tillämplig lag, utesluter vi alla representationer, garantier och
               villkor relaterade till vår webbplats och användningen av denna webbplats (inklusive, utan begränsning,
               alla garantier som följer av lag med avseende på tillfredsställande kvalitet, lämplighet för ändamålet och/eller
               användning av rimlig omsorg och skicklighet). Ingenting i denna ansvarsfriskrivning kommer att:</p>
            <ul>
              <li>begränsa eller utesluta vårt eller ditt ansvar för dödsfall eller personskada till följd av
                 oaktsamhet;
              </li>
              <li>begränsa eller utesluta vårt eller ditt ansvar för bedrägeri eller bedräglig felaktig framställning;</li>
              <li>begränsa något av vårt eller ditt ansvar på något sätt som inte är tillåtet enligt tillämplig lag;
                 eller<br/>
                  utesluta något av vårt eller dina ansvar som kanske inte är undantagna enligt tillämplig lag.
              </li>
            </ul>
            <p>De begränsningar och undantag från ansvar som anges i detta avsnitt och på andra ställen i denna
               ansvarsfriskrivning: (a) omfattas av föregående stycke; och (b) reglera alla skulder som uppstår
               under ansvarsfriskrivningen eller i förhållande till ämnet för denna ansvarsfriskrivning, inklusive
               skulder som uppstår i avtal, i skadestånd (inklusive vårdslöshet) och för brott mot lagstadgade
               plikt.</p>
            <p>I den mån webbplatsen och informationen och tjänsterna på webbplatsen tillhandahålls gratis
               kostnadsfritt kommer vi inte att hållas ansvariga för någon förlust eller skada av något slag.</p>
            <p><strong>Kontaktinformation</strong></p>
            <p>Om det finns några frågor angående dessa villkor kan du kontakta oss med hjälp av informationen
               nedan.</p>
            <p>www.supercoachapp.com<br/>
              Sunnanängsvägen 10<br/>
              ENEBYBERG, 182 36<br/>
              Sweden</p>
            <p>hello@supercoachapp.com</p>
            <p>Last Edited on 2023-03-27</p>
          </div>
        </div>
      </div>
    </article>
  </div>

  );
}

export default TermsOfService;
