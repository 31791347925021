import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (

    <div className="footer" id="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1>Get started with <br className="visible-xs-inline"/>Supercoach today</h1>
            <p className="text-muted">Supercoach is available for iPhone and iPad &amp; Android.</p>
            <p className="lead" style={{marginTop: 40}}>
              <a href="http://appstore.com/supercoachsoccer" className="btn btn-lg btn-primary btn-rounded"
                 style={{margin: 10, width: 280}}>DOWNLOAD FOR iOS</a>
              <a href="https://play.google.com/store/apps/details?id=com.supercoach&amp;hl=en"
                 className="btn btn-lg btn-primary btn-rounded" style={{margin: 10, width: 280}}>DOWNLOAD FOR
                ANDROID</a>
            </p>
          </div>
        </div>

        <div className="footer-links">
          <ul className="list-inline pull-left">
            <li>Have a question? <a href="mailto:hello@supercoachapp.com">hello@supercoachapp.com</a></li>
            <li><Link to="/terms-of-service">Terms of service</Link></li>
            <li><Link to="/privacy-policy">Privacy policy</Link></li>
          </ul>

          <ul className="list-unstyled pull-right">
            <li><a href="https://www.twitter.com/supercoachapp"><i className="fa fa-twitter"></i> Twitter</a></li>
            <li><a href="https://www.facebook.com/supercoachapp"><i className="fa fa-facebook"></i> Facebook</a></li>
            <li><a href="https://www.instagram.com/supercoachapp"><i className="fa fa-instagram"></i> Instagram</a></li>
          </ul>
        </div>
      </div>
    </div>

);
}

export default Footer;
