import React from "react";

import step1 from "../../images/step1.jpg";
import step2 from "../../images/step2.jpg";
import step3 from "../../images/step3.jpg";

function AppDeleteInstructionsEn() {
  return (
    <div className="container">
      <article className="single-page">
        <div className="row">
          <div className="single-page-content col-md-8">
            <div className="single-page-text">
              <h1>Data Deletion Instructions</h1>
              <p>Supercoach does not store your personal data, it is used only for login.</p>
              <p> According to the Google Play rules, we have to provide User Data Deletion Instructions. If you want to delete all your data for the Supercoach, follow these instructions: </p>
              <h2>Option #1</h2>
              <p>Open Supercoach App. Then, go to ”Settings” Tab. Click "Profile settings", click Delete Account, then confirm account deletion by clicking "Delete account" button.</p>
              <div className="delete-images">
                <img src={step1} alt="settings tab"/>
                <img src={step2} alt="profile screen"/>
                <img src={step3} alt="Delete account"/>
              </div>
              <h2>Option #2</h2>
              Go to <a href="https://api.supercoachapp.com/deletion"> DELETE USER ACCOUNT PAGE</a> enter credentials to confirm account deletion.
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}

export default AppDeleteInstructionsEn;
