import React from "react";
import logo from '../../images/logo@2x.png';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./languageSwitcher";

function NavBar() {
  const { t } = useTranslation();

  return (
    <nav className="navbar navbar-inverse navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
            <span className="sr-only">{t("buttons.toggle-navigation")}</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <Link className="navbar-brand" to="/" replace={true}>
            <img src={logo} height="36px" alt='Supercoach'/>
          </Link>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav">
            <li><a href="#about" className="js-scroll" data-target="container-about">{t("buttons.about")}</a></li>
            <li><a href="#getting-started" className="js-scroll" data-target="footer">{t("buttons.getting-started")}</a></li>
          </ul>
          <div className="navbar-buttons">
     
            <a href="https://play.google.com/store/apps/details?id=com.supercoach&amp;hl=en"
              className="btn btn-primary btn-rounded navbar-btn">{t("buttons.download-for", {type: "ANDROID"})}</a>
            <a href="http://appstore.com/supercoachsoccer" className="btn btn-primary btn-rounded navbar-btn"
              style={{marginRight: 10}}>{t("buttons.download-for", {type: "iOS"})}</a>
            <LanguageSwitcher />
          </div>
          
        </div>

      </div>
    </nav>
  );
}

export default NavBar;
