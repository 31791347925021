import React from "react";
import { Routes, Route, Outlet, Navigate, BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import NavBar from "./components/navbar/NavBar";
import './styles/App.scss';
import Footer from "./components/footer/Footer";
import Home from "./pages/home";
import PrivacyPolicy from "./pages/privacyPolicy";
import TermsOfService from "./pages/termsOfService";
import AppDeleteInstructions from "./pages/app-delete-instructions";

function App() {
  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <NavBar/>
          <Routes>
            <Route path="/" element={<Layout/>}>
              <Route index element={<Home/>}/>
              <Route path="privacy-policy" element={<PrivacyPolicy/>}/>
              <Route path="terms-of-service" element={<TermsOfService/>}/>
              <Route path="app-delete" element={<AppDeleteInstructions/>}/>
              <Route path="*" element={<Navigate to="/" replace={true}/>}/>
            </Route>
          </Routes>
          <Footer/>
        </BrowserRouter>
      </I18nextProvider>
    </div>
  );
}

function Layout() {
  return (
    <div className="layout">
      <Outlet/>
    </div>
  );
}

export default App;
