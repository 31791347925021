import React from "react";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  return (
    <div className="container">
    <article className="single-page">
      <div className="row">
        <div className="single-page-content col-md-8">
          <div className="single-page-text">
            <h1>Integritetspolicy</h1>
            <p><strong>General</strong><br/>
              På Supercoach vet vi att du bryr dig om din personliga information, så vi har utarbetat denna integritetspolicy
               (vår "Integritetspolicy") för att förklara hur vi samlar in, använder och delar den. Genom att använda eller komma åt tjänsten,
               godkänner du villkoren i denna integritetspolicy.</p>
            <p>Termer med versaler som inte definieras här har de betydelser som anges i villkoren (”Regler
               och villkor”), finns at&nbsp;
              <Link to="/terms-of-service">http://supercoachapp.com/terms-of-service/</Link>. Vi kan komma att uppdatera vår integritetspolicy 
  för att återspegla ändringar i vår informationspraxis. Om vi gör detta och förändringarna är materiella, 
               kommer vi att lägga upp ett meddelande om att vi har gjort ändringar i denna integritetspolicy på webbplatsen i minst 7 dagar
               efter att ändringarna har gjorts, och vi kommer att ange datumet för dessa villkor senast reviderades längst ned på
               sekretesspolicyn. Alla ändringar av denna integritetspolicy kommer att träda i kraft tidigast (i) i 
               slutet av den 7-dagarsperioden eller (ii) första gången du använder eller använder tjänsten efter sådana ändringar.
            </p>
            <p><strong>Information vi samlar in</strong><br/>
              När du använder Supercoach ger du oss två typer av information: (i) information som du skickar via
               Tjänsten och (ii) information om din användning av Tjänsten som samlas in av oss när du interagerar med
               Tjänsten.</p>
            <p>När du går in på webbplatsen samlar vi in din webbläsartyp och din IP-adress (en unik adress som
               identifierar din dator på Internet). När du använder vår mobilapplikation samlar vi in din enhetstyp,
               ditt enhets-ID och din IP-adress. Dessutom lagrar vi viss information från din webbläsare
               genom "cookies". En cookie är en bit data som lagras på användarens dator kopplad till information om
               användaren. Vi använder sessions-ID-cookies för att bekräfta att användare är inloggade. Om du inte vill att information
               samlas in genom användning av cookies finns det en enkel procedur i de flesta webbläsare som gör att du kan
               neka eller acceptera cookie-funktionen; dock bör du notera att cookies kan vara nödvändiga för att tillhandahålla dig
               vissa funktioner tillgängliga på webbplatsen (t.ex. anpassad leverans av information).</p>
            <p>Genom registreringsprocessen kan du ge oss ditt namn och din e-postadress och annan
               information som kan begäras under registreringsprocessen. </p>
            <p>När du använder tjänsten kan du skicka information och innehåll till din profil, generera aktivitetsdata
               genom att delta i utbildningsaktiviteter på Tjänsten, eller skicka meddelanden och på annat sätt överföra
               information till andra användare. Vi lagrar denna information så att vi kan tillhandahålla dig Tjänsten och erbjuda
               personliga funktioner.</p>
            <p>Vi delar inte din personliga information med tredje part såvida det inte är nödvändigt för att erbjuda
               Tjänsten eller krävs enligt lag (se nedan för detaljer).</p>
            <p><strong>Användning av information som erhållits av Supercoach</strong><br/>
              Vi kan använda din kontaktinformation för att skicka meddelanden till dig om nya tjänster som erbjuds av
               Supercoach som vi tror att du kan tycka är värdefull. Supercoach kan också skicka servicerelaterade meddelanden till dig
               genom den allmänna driften av Tjänsten. I allmänhet kan du välja att avprenumerera
               på sådana e-postmeddelanden.</p>
            <p>Profilinformation används av Supercoach för att presenteras och redigeras av dig när du kommer åt
               Tjänsten. </p>
            <p>Supercoach kan använda eller dela aggregerad eller anonym data som samlats in via Tjänsten, inklusive
               Aktivitetsdata, för ändamål som att förstå eller förbättra tjänsten.</p>
            <p><strong>Information erhållen av tredje part</strong><br/>
              För att stödja och förbättra tjänsten kan vi samarbeta med en mängd olika annonsnätverk från tredje part,
               leverantörer av marknadsföringsanalystjänster och webbplatsanalysföretag (tillsammans, "Tredjepartsannonsering
               Tjänsteleverantörer"). Dessa tredjepartsleverantörer av reklamtjänster kan samla in och använda icke-personligt
               identifierbar information om dina besök på och användning av webbplatsen och mobilapplikationen samt
               andra webbplatser för att ge dig reklam och innehåll som är skräddarsytt för att möta dina önskemål
               och troliga intressen eller bättre förstå annonsens effektivitet. Sammanlagd demografi och intressedata kan
               även användas för marknadsundersökningsändamål.</p>
            <p>Du kan välja bort att ta emot personliga annonser från annonsörer och annonsnätverk som är medlemmar i
               Network Advertising Initiative (”NAI”) eller som följer DAA:s självreglerande principer för online
               beteendereklam med sina respektive opt-out-verktyg. NAI:s opt-out-verktyg finns här
               http://www.networkadvertising.org/choices/ och DAA:s opt-out-verktyg finns här
               http://www.aboutads.info/choices/.<br/>
                Dessutom kan dina mobila enheter erbjuda inställningar som gör att du kan göra val om
                 insamling, användning eller överföring av mobilappsinformation för beteendebaserad annonsering online (t.ex.
                 Apple iOS reklam-ID och Google Androids reklam-ID). Observera att det inte gör det att välja bort
                 förhindra att alla annonser visas för dig.</p>
            <p><strong>Dela din personligt identifierbara information med tredje part</strong><br/>
               Supercoach delar din personligt identifierbara information endast när det rimligen är nödvändigt att erbjuda
               Tjänsten, enligt lag eller tillåten av dig. Till exempel:<br/>
              Vi kan tillhandahålla personligt identifierbar information till tjänsteleverantörer som hjälper oss att ge dig
               Tjänsten, som att vara värd för tjänsten på en samlokaliseringsanläggning eller skicka e-postuppdateringar. I samband
               med dessa operationer kan våra tjänsteleverantörer ha tillgång till personligt identifierbar information för
               en begränsad tid. När vi använder tjänsteleverantörer för att behandla eventuella personligt identifierbar
               information, implementerar vi rimliga avtalsskydd som begränsar användningen av den
               personligt identifierbara informationen för tillhandahållande av tjänster till Supercoach.</p>
            <p>Vi kan behöva komma åt och avslöja personligt identifierbar information som svar på laglig
               framställningar, såsom stämningar eller domstolsbeslut, eller i enlighet med tillämpliga lagar.</p>
            <p>Dessutom kan vi komma åt och dela konto- eller annan personligt identifierbar information när vi
               anser att det är nödvändigt för att följa lag, för att skydda våra intressen eller egendom, för att förhindra bedrägeri eller
               annan olaglig aktivitet som utförs genom Tjänsten eller använder Supercoach-namnet, eller för att förhindra
               överhängande skada. Detta kan inkludera att få tillgång till och dela personligt identifierbar information med andra
               företag, advokater, ombud eller statliga myndigheter.</p>
            <p>Om ägandet av hela eller väsentligen hela Supercoach-verksamheten eller enskilda affärsenheter
               eller tillgångar som ägs av Supercoach som är relaterade till Tjänsten, skulle förändras, kan din
               personligt identifierbara information överföras till den nya ägaren. I varje sådan överföring av
               information, skulle din personligt identifierbara information förbli föremål för detta avsnitt.</p>
            <p><strong>Uppdatera eller radera din personligt identifierbara information</strong><br/>
              Du har när som helst rätt att radera ditt konto hos Supercoach genom att kontakta oss på
               hello@supercoachapp.com. Efter att ditt konto har raderats kommer vi att behålla samlade eller anonyma uppgifter
               som samlats in genom Tjänsten, inklusive aktivitetsdata, som kan användas av Supercoach och delas med
               tredje part på något sätt.</p>
            <p><strong>Tredjeparts webbplatser och länkar</strong><br/>
              Observera att du kan ha cookies placerade på din dator av tredje parts webbplatser som hänvisar dig till
               vår tjänst. Även om vi inte delar din personliga information med dessa tredje parts webbplatser om inte
               det är rimligen nödvändigt att erbjuda Tjänsten, kanske de kan länka vissa icke-personligt
               identifierbar information som vi överför till dem med personlig information som de tidigare samlat in från
               dig. Läs integritetspolicyn för varje webbplats du besöker för att bättre förstå deras integritets-
               praxis. Dessutom vill Supercoach informera dig om att när du klickar på länkar (inklusive
               reklambanner), som tar dig till tredje parts webbplatser, kommer du att bli föremål för tredje parts
               sekretesspolicyer.</p>
            <p>Våra tjänster kan innehålla länkar till andra webbplatser som drivs av tredje part. Supercoach styr inte
               sådana andra webbplatser och är inte ansvarig för deras innehåll, deras sekretesspolicy eller deras användning av
               personlig information, inklusive all personlig eller ekonomisk information som samlats in av tredje parts
               betalningsprocessor för att behandla betalningar för köp i appar. Supercoachs inkludering av sådana länkar skall
               inte antyda något stöd för innehållet på sådana webbplatser eller av deras ägare eller operatörer förutom som
               avslöjas på tjänsterna. All information som du skickar direkt till dessa tredje parter är föremål för
               till den tredje partens integritetspolicy.</p>
            <p><strong>Länkar</strong><br/>
              Tjänsten kan innehålla länkar till andra webbplatser. Vi är inte ansvariga för integritetsspraxis för
               andra webbplatser. Vi uppmuntrar användare att vara medvetna om när de lämnar Tjänsten för att läsa integritetspolicyn
               från andra webbplatser som samlar in personligt identifierbar information. Denna integritetspolicy
               gäller endast information som samlas in av Supercoach via Tjänsten.</p>
            <p><strong>Kontakta oss</strong><br/>
              För att förstå mer om vår integritetspolicy, få tillgång till din information eller ställa frågor om vår integritetspolicy
               eller utfärda ett klagomål, vänligen kontakta oss på hello@supercoachapp.com.</p>
            <p>www.supercoachapp.com<br/>
              Sunnanängsvägen 10<br/>
              ENEBYBERG, 182 36<br/>
              Sweden</p>
            <p>hello@supercoachapp.com</p>
            <p>Last Edited on 2023-03-26</p>
          </div>
        </div>
      </div>
    </article>
  </div>

  );
}

export default PrivacyPolicy;
