import { useTranslation } from "react-i18next";
import en from "./en";
import sv from "./sv";

function PrivacyPolicy() {
  const { i18n } = useTranslation();

    switch (i18n.language) {
        case 'en':
            return en();
        case 'sv':
            return sv();
        default:
            return en();        
    }
}

export default PrivacyPolicy;
