import React from "react";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  return (
    <div className="container">
      <article className="single-page">
        <div className="row">
          <div className="single-page-content col-md-8">
            <div className="single-page-text">
              <h1>Privacy Policy</h1>
              <p><strong>General</strong><br/>
                At Supercoach, we know you care about your personal information, so we have prepared this privacy policy
                (our “Privacy Policy”) to explain how we collect, use and share it. By using or accessing the Service,
                you agree to the terms of this Privacy Policy.</p>
              <p>Capitalized terms not defined here have the meanings set forth in the terms and conditions (the “Terms
                and Conditions”), located at&nbsp;
                <Link to="/terms-of-service">http://supercoachapp.com/terms-of-service/</Link>. We may update our Privacy
                Policy to reflect changes to our information practices. If we do this and the changes are material, we
                will post a notice that we have made changes to this Privacy Policy on the Website for at least 7 days
                after the changes are made, and we will indicate the date these terms were last revised at the bottom of
                the Privacy Policy. Any revisions to this Privacy Policy will become effective the earlier of (i) the
                end of that 7-day period or (ii) the first time you access or use the Service after any such changes.
              </p>
              <p><strong>Information We Collect</strong><br/>
                When you use Supercoach you provide us with two types of information: (i) information you submit via the
                Service and (ii) information regarding your use of the Service collected by us as you interact with the
                Service.</p>
              <p>When you enter the Website, we collect your browser type and your IP address (a unique address that
                identifies your computer on the Internet). When you use our mobile Application, we collect your device
                type, your device ID, and your IP address. In addition, we store certain information from your browser
                using ”cookies.” A cookie is a piece of data stored on the user’s computer tied to information about the
                user. We use session ID cookies to confirm that users are logged in. If you do not want information
                collected through the use of cookies, there is a simple procedure in most browsers that allows you to
                deny or accept the cookie feature; however, you should note that cookies may be necessary to provide you
                certain features available on the Website (e.g., customized delivery of information).</p>
              <p>Through the registration process you may provide us with your name and email address, and other
                information that may be requested during the registration process. </p>
              <p>When you use the Service you may submit information and content to your profile, generate Activity Data
                through engaging in educational activities on the Service, or send messages and otherwise transmit
                information to other users. We store this information so that we can provide you the Service and offer
                personalized features.</p>
              <p>We do not share your personal information with third-parties unless it is necessary to offer the
                Service or required by law (see below for specifics).</p>
              <p><strong>Use of Information Obtained by Supercoach</strong><br/>
                We may use your contact information to send you notifications regarding new services offered by
                Supercoach that we think you may find valuable. Supercoach may also send you service-related
                announcements from time to time through the general operation of the Service. Generally, you may opt out
                of such emails.</p>
              <p>Profile information is used by Supercoach to be presented back to and edited by you when you access the
                Service. </p>
              <p>Supercoach may use or share aggregate or anonymous data collected through the Service, including
                Activity Data, for purposes such as understanding or improving the service.</p>
              <p><strong>Information Obtained by Third Parties</strong><br/>
                To support and enhance the Service, we may work with a variety of third party advertising networks,
                marketing analytics service providers and website analysis firms (collectively, “Third Party Advertising
                Service Providers”). These Third Party Advertising Service Providers may collect and use non-personally
                identifiable information about your visits to and use of the Website and mobile Application as well as
                other websites in order to serve you with advertisements and content tailored to meet your preferences
                and likely interests or better understand ad effectiveness. Aggregate demographic and interest data may
                also be used for market research purposes.</p>
              <p>You can opt-out of receiving personalized ads from advertisers and ad networks that are members of the
                Network Advertising Initiative (“NAI”) or who follow the DAA Self-Regulatory Principles for Online
                Behavioral Advertising using their respective opt-out tools. The NAI’s opt-out tool can be found here
                http://www.networkadvertising.org/choices/ and the DAA’s opt out tool can be found here
                http://www.aboutads.info/choices/.<br/>
                  In addition, your mobile devices may offer settings that enable you to make choices about the
                  collection, use, or transfer of mobile app information for online behavioral advertising (for example,
                  Apple iOS’ Advertising ID and Google Android’s Advertising ID). Please note that opting out does not
                  prevent the display of all advertisements to you.</p>
              <p><strong>Sharing Your Personally-Identifiable Information With Third Parties</strong><br/>
                Supercoach shares your personally-identifiable information only when it is reasonably necessary to offer
                the Service, legally required, or permitted by you. For example:<br/>
                We may provide personally-identifiable information to service providers who help us bring you the
                Service, such as hosting the Service at a co-location facility or sending email updates. In connection
                with these operations, our service providers may have access to personally-identifiable information for
                a limited time. When we utilize service providers for processing any personally-identifiable
                information, we implement reasonable contractual protections limiting the use of that
                personally-identifiable information to the provision of services to Supercoach.</p>
              <p>We may be required to access and disclose personally-identifiable information in response to lawful
                requests, such as subpoenas or court orders, or in compliance with applicable laws. </p>
              <p>Additionally, we may access and share account or other personally-identifiable information when we
                believe it is necessary to comply with law, to protect our interests or property, to prevent fraud or
                other illegal activity perpetrated through the Service or using the Supercoach name, or to prevent
                imminent harm. This may include accessing and sharing personally-identifiable information with other
                companies, lawyers, agents or government agencies.</p>
              <p>If the ownership of all or substantially all of the Supercoach business, or individual business units
                or assets owned by Supercoach that are related to the Service, were to change, your
                personally-identifiable information may be transferred to the new owner. In any such transfer of
                information, your personally-identifiable information would remain subject to this section.</p>
              <p><strong>Updating or Deleting Your Personally-Identifiable Information</strong><br/>
                You have at all times the right to delete your account with Supercoach by contacting us at
                hello@supercoachapp.com. After your account is deleted we will retain aggregate or anonymous data
                collected through the Service, including Activity Data, which may be used by Supercoach and shared with
                third parties in any manner.</p>
              <p><strong>Third Party Werbsites and Links</strong><br/>
                Please note that you may have cookies placed on your computer by third party websites that refer you to
                our Service. Although we do not share your personal information with these third party websites unless
                it is reasonably necessary to offer the Service, they may be able to link certain non-personally
                identifiable information we transfer to them with personal information they previously collected from
                you. Please review the privacy policies of each website you visit to better understand their privacy
                practices. In addition, Supercoach would like to inform you that anytime you click on links (including
                advertising banners), which take you to third party websites, you will be subject to the third parties’
                privacy policies.</p>
              <p>Our Services may contain links to other sites operated by third parties. Supercoach does not control
                such other sites and is not responsible for their content, their privacy policies, or their use of
                personal information, including any personal or financial information collected by our third party
                payment processor to process payments for in-app purchases. Supercoach’s inclusion of such links does
                not imply any endorsement of the content on such sites or of their owners or operators except as
                disclosed on the Services. Any information submitted by you directly to these third parties is subject
                to that third party’s privacy policy.</p>
              <p><strong>Links</strong><br/>
                The Service may contain links to other websites. We are not responsible for the privacy practices of
                other websites. We encourage users to be aware when they leave the Service to read the privacy
                statements of other websites that collect personally identifiable information. This Privacy Policy
                applies only to information collected by Supercoach via the Service.</p>
              <p><strong>Contact Us</strong><br/>
                To understand more about our Privacy Policy, access your information, or ask questions about our privacy
                practices or issue a complaint, please contact us at hello@supercoachapp.com.</p>
              <p>www.supercoachapp.com<br/>
                Svartmangatan 9<br/>
                Stockholm, 111 29<br/>
                Sweden</p>
              <p>hello@supercoachapp.com</p>
              <p>Last Edited on 2018-05-24</p>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}

export default PrivacyPolicy;
